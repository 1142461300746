import clsx from "clsx";
import IconBox from "@root/common/IconBox";
import LocaleService from "@root/services/locale";
import React, { useEffect, useState } from "react";
import ExpressDelivery from "@root/common/ExpressDelivery";
import { map, filter, toLower, includes, isUndefined } from "lodash";

import styles from "./styles.module.scss";

function Options({value, list, onDone}) {
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    setFiltered(list);
  }, [list]);

  const onChangeInput = (event) => {
    let value = event.target.value;
    let filtered = filter(list, (item) => {
      let lowervalue = toLower(value);
      let lowertitle = toLower(item.label);
      return includes(lowertitle, lowervalue);
    });
    setFiltered(filtered);
  };

  return (
    <div className={styles.options}>
      <input
        placeholder="Search"
        onChange={onChangeInput}
        className={styles.options_search}
      />
      <div className={styles.options_options}>
        <ul className={styles.options_items}>
          {map(filtered, (option) => {
            let isSelected = !isUndefined(value) && option.value === value;
            return (
              <li key={option?.value} className={styles.options_item}>
                <a
                  onClick={() => onDone(option.value)}
                  className={clsx(
                    styles.options_link,
                    isSelected && styles.selected
                  )}
                >
                  <IconBox
                    name={isSelected ? "radiobox-marked" : "radiobox-blank"}
                    className={styles.options_icon}
                  />
                  <span className={styles.options_text}>
                    {option?.label}
                  </span>
                  {option?.express_delivery_available && <ExpressDelivery size={8} />}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Options;
