import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.scss';
import DefaultSelect from './components/DefaultSelect';
import SearchSelect from './components/SearchSelect';

function FormSelect({
  error,
  value,
  options,
  disabled,
  onChange,
  variant = 'search',
  attributes,
}) {
  return (
    <>
      <div
        className={clsx(
          styles.formselect,
          error && styles.error,
          disabled && styles.disabled,
        )}>
        {variant === 'default' && (
          <DefaultSelect
            value={value}
            options={options}
            disabled={disabled}
            onChange={onChange}
            {...attributes}
          />
        )}
        {variant === 'search' && (
          <SearchSelect
            value={value}
            options={options}
            disabled={disabled}
            onChange={onChange}
            {...attributes}
          />
        )}
      </div>
      {error && <span className={styles.formselect_error}>{error}</span>}
    </>
  );
}

export default FormSelect;
