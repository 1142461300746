import clsx from 'clsx';
import React from 'react';
import {get, isFunction} from 'lodash';
import LocaleService from '@root/services/locale';

import styles from './styles.module.scss';

function FormText({error, value, disabled, onChange, attributes}) {
  const {t} = LocaleService.useLocale();

  const onChangeText = event => {
    if (isFunction(onChange)) onChange(event.target.value);
  };

  return (
    <div className={styles.formtext}>
      <div className={clsx(
        styles.formtext_box, 
        error && styles.error,
        disabled && styles.disabled, 
      )}>
        <input
          autoCorrect="off"
          autoCapitalize="off"
          autoComplete="off"
          disabled={disabled}
          onChange={onChangeText}
          value={value ? value : ''}
          className={styles.formtext_input}
          {...attributes}
        />
      </div>
      <span className={styles.formtext_error}>{error}</span>
    </div>
  );
}

export default FormText;
