import config from '@root/config';
import RequestService from './request';
import {get, isUndefined, omit} from 'lodash';
import * as _validation from 'validate.js';

class Validation {
  constructor() {
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  error(errors, key) {
    return get(errors, [key, 0, 'en_us']);
  }

  async isValid(data, key, customRules = null) {
    let rules = customRules ? customRules : await this.fetchRules(key);
    let valid = _validation.validate(data, rules);
    if (isUndefined(valid)) return true;
    else throw valid;
  }

  async fetchRules(key) {
    let result = await RequestService.get(
      `${config.base_path}_svc/api/md/v1/validation/`,
      {
        params: {key, language: 'en'},
      },
    );
    return result.data;
  }
}
const ValidationService = new Validation();
export default ValidationService;
