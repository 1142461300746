import config from "@root/config";
import LocaleService from "./locale";
import RequestService from "./request";

class Directory {
  constructor() {
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  getCountriesList() {
    return RequestService.$get(
      `${config.base_path}rest/${LocaleService.current}/V1/customapi/getNationalityList`
    );
  }

  getCitiesList(country_code = config.country_code) {
    return RequestService.$get(
      `${config.base_path}${LocaleService.current}/rest/V1/customapi/directory/region/${country_code}`
      // `${config.base_path}${LocaleService.current}/rest/V1/directory/countries/${country_code}`
    );
  }

  getRegionsList(region_id) {
    return RequestService.$get(
      `${config.base_path}${LocaleService.current}/rest/V1/customapi/directory/city/region_id/${region_id}`
    );
  }
}
const DirectoryService = new Directory();
export default DirectoryService;
