import clsx from 'clsx';
import React from 'react';
import {isFunction, map} from 'lodash';
import {isValidString} from '@root/libs/utils';

import styles from './styles.module.scss';

function DefaultSelect({value, options, disabled, onChange, ...props}) {
  const onChangeSelect = event => {
    let value = event.target.value;
    if (isFunction(onChange)) onChange(value);
  };

  return (
    <select
      value={value}
      disabled={disabled}
      onChange={onChangeSelect}
      className={clsx(styles.select, !isValidString(value) && styles.invalid)}
      {...props}>
      <option value="">{props?.placeholder}</option>
      {map(options, option => {
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        );
      })}
    </select>
  );
}

export default DefaultSelect;
