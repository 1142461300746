import clsx from "clsx";
import LocaleService from "@root/services/locale";
import React, { useState, forwardRef, useImperativeHandle } from "react";

import styles from "./styles.module.scss";

function BottomSheet(props, ref) {
  const { t } = LocaleService.useLocale();
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    show() {
      setShow(true);
    },
    hide() {
      setShow(false);
    },
  }));

  const hideSheet = () => {
    setShow(false);
  }

  if(!show) return null;
  return (
    <div className={clsx(styles.bottomsheet, show && styles.active)}>
      <div onClick={hideSheet} className={styles.bottomsheet_overlay}></div>
      <div className={styles.bottomsheet_content}>{show && props.children}</div>
    </div>
  );
}

export default forwardRef(BottomSheet);
