import clsx from "clsx";
import IconBox from "../IconBox";
import LocaleService from "@root/services/locale";
import config from "@root/config";
import { isNull, isUndefined, map } from "lodash";
import React, { useState, useEffect } from "react";

import styles from "./styles.module.scss";

function FormMobile({ error, value, disabled, onChange, attributes }) {
  const { t } = LocaleService.useLocale();
  const areaCodes = config.mobile_area_codes;
  const countryCode = config.mobile_country_code;
  const [number, setNumber] = useState("");
  const regex = new RegExp("([5][0|2|4|5|6|8])(.*)");
  const [visibility, setVisibility] = useState(false);
  const [selected, setSelected] = useState(areaCodes[0]);

  useEffect(() => {
    if (isUndefined(value) || isNull(value)) return;
    let result = regex.exec(value);
    if (!isNull(result)) {
      setNumber(result[2]);
      setSelected(result[1]);
    }
  }, [value]);

  const onPressToggle = () => {
    if(disabled) return;
    setVisibility(!visibility);
  };

  const onClickCode = (code) => {
    setSelected(code);
    if (isNull(number) || number === "") onChange(null);
    else onChange(`${countryCode}${code}${number}`);
  };

  const onChangeInput = (element) => {
    let value = element.target.value;
    setNumber(value);
    if (value.length === 0) onChange(null);
    else onChange(`${countryCode}${selected}${value}`);
  };

  return (
    <div className={styles.formmobile} dir="ltr">
      <div className={clsx(styles.formmobile_box, disabled && styles.disabled, error && styles.error)}>
        <span className={styles.formmobile_code}>{countryCode}</span>
        <div onClick={onPressToggle} className={styles.formmobile_area}>
          {!visibility && (
            <span className={styles.formmobile_areatext}>{selected}</span>
          )}
          <div
            className={clsx(
              styles.formmobile_areabox,
              visibility && styles.active
            )}
          >
            {map(areaCodes, (item) => {
              return (
                <span
                  key={item}
                  onClick={() => onClickCode(item)}
                  className={clsx(
                    styles.formmobile_areatext,
                    item === selected && styles.current
                  )}
                >
                  {item}
                </span>
              );
            })}
          </div>
          <IconBox
            name={visibility ? "chevron-left" : "chevron-right"}
            className={styles.formmobile_chev}
          />
        </div>
        <input
          type="tel"
          value={number}
          disabled={disabled}
          onChange={onChangeInput}
          className={styles.formmobile_input}
          {...attributes}
        />
      </div>
      {error && <span className={styles.formmobile_error}>{error}</span>}
    </div>
  );
}

export default FormMobile;
