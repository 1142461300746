import React, {useRef} from 'react';
import PopModal from '@root/common/PopModal';
import DeviceView from '@root/common/DeviceView';
import {find, isFunction, toString} from 'lodash';
import BottomSheet from '@root/common/BottomSheet';

import Options from '../Options';
import styles from './styles.module.scss';

function SearchSelect({value, disabled, options, onChange, ...props}) {
  const sheetRef = useRef();

  const onClickSelect = () => {
    sheetRef.current.show();
  };

  const onClickOption = option => {
    if (isFunction(onChange)) onChange(option);
    sheetRef.current.hide();
  };

  const valueLabel = () => {
    let found = find(options, {value: toString(value)});
    return found ? found.label : '';
  };

  return (
    <>
      <input
        type="text"
        readOnly
        onFocus={onClickSelect}
        disabled={disabled}
        value={valueLabel()}
        className={styles.input}
        {...props}
      />
      <DeviceView
        mobile={
          <BottomSheet ref={sheetRef}>
            <Options value={value} list={options} onDone={onClickOption} />
          </BottomSheet>
        }
        desktop={
          <PopModal bgaction ref={sheetRef}>
            <Options value={value} list={options} onDone={onClickOption} />
          </PopModal>
        }
      />
    </>
  );
}

export default SearchSelect;
