import clsx from 'clsx';
import React from 'react';
import LocaleService from '@root/services/locale';

import styles from './styles.module.scss';

function BButton({
  type = 'primary',
  label,
  onPress,
  className,
  loading,
  disabled,
}) {
  const {t} = LocaleService.useLocale();

  const buttonStyles = () => {
    if (type === 'primary') return styles.bbutton_primary;
    else if (type === 'secondary') return styles.bbutton_secondary;
  };

  const isDisabled = () => {
    return loading || disabled;
  };

  const onClickButton = () => {
    if (!isDisabled()) onPress();
  };

  return (
    <a
      onClick={onClickButton}
      className={clsx(
        styles.bbutton,
        buttonStyles(),
        className,
        isDisabled() && styles.disabled,
      )}>
      <span className={styles.bbutton_text}>
        {loading ? t('br_please_wait') : label}
      </span>
    </a>
  );
}

export default BButton;
